import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container flex py-20">
      <div className="flex flex-col justify-around h-full w-full items-center">
        <img src="https://media.giphy.com/media/fpXxIjftmkk9y/giphy.gif" alt="doggo looking at you" className="w-1/2"/>
        <h4 className="mt-16 mb-10">Whoops! There's nothing to see here.</h4>
        <div className="flex">
          <Link to={'/'} className="mr-10 font-header font-bold cursor-pointer">home</Link>
          <p onClick={() => window.history.back()} className="font-header font-bold cursor-pointer">go back</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
